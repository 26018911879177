import * as React from "react";
import Layout from "../../components/Layout";

const AboutPage = () => {
  return (
    <Layout pageTitle="About Me">
      <div data-section="about" className="main-section about">
        <div className="main-section-inner text-container">
          <div className="about-top">
            <div className="about-section about-me">
              <div className="about-section-inner">
                <h2 className="about-header">About Me</h2>
                <div className="about-section-content">
                  <p>
                    I'm a creative individual who adheres to a fluid product
                    workflow and ideology that removes friction between design
                    and development. I believe that empowering designers to
                    author code can help to promote an ecosystem of
                    cross-functional collaboration throughout the product
                    iteration cycle. Designers who understand code are better
                    equipped to confidently interact with engineering, and
                    engineers who are talented in design are more empathetic
                    concerning the motivation behind design requests.
                  </p>
                  <p>
                    This is my personal design process and the one I'm most
                    experienced working with in a team setting - it's a
                    philosophy that I believe in strongly, and something I'd
                    love to bring to new companies and products in the future.
                  </p>
                </div>
              </div>
            </div>
            <div className="about-section my-history">
              <div className="about-section-inner">
                <h3 className="about-header">My History</h3>
                <div className="about-section-content">
                  <p>
                    In many ways my work has been in gradual motion towards
                    deeper understanding of engineering-focused skills. I began
                    my career as a traditional print designer, working on
                    typesetting and layout design, moved towards becoming an
                    expert in CSS and web UI, and in more recent years have
                    developed entire full-stack applications. Despite the
                    technology I'm using, visual design must remain at the core
                    of everything that I do - aesthetics, usability, and
                    bringing joy and wonder into the world through the visual
                    medium is important to me.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="about-section tools-section">
            <div className="about-section-inner">
              <h3 className="about-header">My Tools</h3>
              <p className="tools-intro">
                Here's an incomplete listing of some tools I use to get stuff
                done.
              </p>
              <div className="tools">
                <div className="tool">
                  <div className="tool-inner">
                    <h4>Typescript</h4>
                    <p>
                      Typescript was initially a hard sell for me, but lately I
                      have started to feel like the type-checker is more
                      enabling than restricting, which is a really nice place to
                      be.
                    </p>
                  </div>
                </div>
                <div className="tool">
                  <div className="tool-inner">
                    <h4>Tailwind</h4>
                    <p>
                      Lately I'm using Tailwind for my CSS concerns. I have a
                      deep history with CSS, from vanilla, to SCSS, to
                      developing my own frameworks - I still like rolling my own
                      design systems with SCSS a lot, but Tailwind is the least
                      bad CSS design system I've used.
                    </p>
                  </div>
                </div>
                <div className="tool">
                  <div className="tool-inner">
                    <h4>React/NextJS</h4>
                    <p>
                      My recent work has been full-stack development projects
                      using React/NextJS. This has enabled me with greater
                      control over the entire user experience, from design to
                      databases.
                    </p>
                  </div>
                </div>
                <div className="tool">
                  <div className="tool-inner">
                    <h4>Figma</h4>
                    <p>
                      While I prefer to use a code first approach to design for
                      my personal projects. I have experience using Figma when
                      working with other designers and then realizing those
                      designs in code.
                    </p>
                  </div>
                </div>
                <div className="tool">
                  <div className="tool-inner">
                    <h4>Adobe Creative Suite</h4>
                    <p>
                      I cut my teeth as a designer on typesetting with Adobe
                      InDesign. Adobe products aren't always the tool I reach
                      for right away, but I know how to get things done across
                      the suite of Adobe visual design products.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AboutPage;
